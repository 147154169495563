<template>
	<S2SForm title="Close Possible Bags">
		<SimpleDialog v-model="dialog" title="Bag Close Confirm">
			<p>
				Are you sure you want to manually close bag
				<strong>{{ bagNumber }}</strong
				>?
			</p>
			<v-flex xs12 class="text-xs-right" slot="button-container">
				<v-btn text @click="dialog = false">Cancel</v-btn>
				<v-btn
					color="accent"
					@click="
						dialog = false;
						closeBag(bagId);
					"
					>Agree</v-btn
				>
			</v-flex>
		</SimpleDialog>
		<S2SCard>
			<v-data-table :headers="bagHeaders" :items="bags" class="elevation-1" item-key="id" :loading="bagsStatus.loading" :options.sync="pagination">
				<template v-slot:item.close="{ item }">
					<v-btn
						color="error"
						@click="
							dialog = true;
							bagNumber = item.bagserialnumber;
							bagId = item.id;
						"
						>Close</v-btn
					>
				</template>
			</v-data-table>
		</S2SCard>
	</S2SForm>
</template>

<script>
import Vue from "vue";

import { Bag } from "@/store/modules/safes";

import { Status } from "@/store/utils";
import SimpleDialog from "@/components/SimpleDialog.vue";
import { Utils } from "@/utils";

export default Vue.extend({
	name: "ClosePossibleBags",

	components: { SimpleDialog },

	data: function() {
		return {
			dialog: false,
			bagNumber: null,
			bagId: undefined,
			pagination: {
				descending: true,
				itemsPerPage: 10,
				sortBy: ["lastevent"]
			},

			bagHeaders: [
				{ text: "Bag Serial", value: "bagserialnumber" },
				{ text: "Amount", value: "amount", type: "currency", width: "250" },
				{ text: "Active", value: "active" },
				{ text: "Last Event", value: "lastevent", type: "dateTime" },
				{ text: "Vendor Name", value: "vendordescription" },
				{ text: "Client Name", value: "clientdescription" },
				{ text: "Removal Amount", value: "removalamount", type: "currency" },
				{ text: "Removal Date", value: "removaldate", type: "dateTime" },
				{ text: "Close", value: "close", type: "action" }
			]
		};
	},

	computed: {
		bags: function() {
			return this.$store.state.safes.bags;
		},
		bagsStatus: function() {
			return this.$store.state.safes.bagsStatus;
		}
	},

	mounted: function() {
		this.$store.dispatch("safes/fetchPossibleCloseBags");
	},

	methods: {
		formatColumn(header, item) {
			return Utils.formatColumn(header, item);
		},
		closeBag(bagId) {
			this.$store.dispatch("safes/closeBag", bagId);
		}
	}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
	font-weight: normal;
}

ul {
	list-style-type: none;
	padding: 0;
}

li {
	display: inline-block;
	margin: 0 10px;
}

a {
	color: #42b983;
}
</style>
